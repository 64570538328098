export const reqMealPlansActiveProgramMonths = async(
  axios, {
    programId = '',
  }) => {
  let url = '/v1/clients/meal-plans/active-program/months'
  if (programId) {
    url = url + `?programId=${programId}`
  } 
  return axios.get(url)
}

export const reqMealPlansActiveShowPopup = async(
  axios, { programId }) => {
  // return axios.get('/v1/clients/meal-plans/read/status', {
  //   params: {
  //     programId,
  //     month: 1,
  //   },
  // })
  return null
}

export const reqClient_mealPlans_downloadFilename = async(
  axios, {
    programId,
    month,
  }) => {
  return axios.get('/v1/clients/meal-plans/download/filename', {
    params: {
      programId,
      month,
    },
  })
}

export const reqClient_mealPlans_download = async(
  axios, {
    programId,
    month,
  }, {
    responseType,
  }) => {
  return axios.get('/v1/clients/meal-plans/download', {
    params: {
      programId,
      month,
    },
    responseType,
  })
}

export const reqClient_mealPlans_read_put = async(
  axios, {
    clientId,
    programId,
    month,
  }) => {
  // return axios.put(`/v1/clients/meal-plans/read?clientId=${clientId}&programId=${programId}&month=${month}`)
  return null
}

export const reqClient_mealPlans_getMenuRecommendationWeekDays = async(axios, { clientId, programId, month }) => {
  return axios.get(`/v1/clients/meal-plans/menu-recommendation/weeks-days?clientId=${clientId}&programId=${programId}&month=${month}`)
}
export const reqClient_mealPlans_getMenuRecommendationCalendar = async(axios, { clientId, programId }) => {
  return axios.get(`/v1/clients/meal-plans/menu-recommendation/calendar?clientId=${clientId}&programId=${programId}`)
}

export const reqClient_mealPlans_getMenuRecommendationPreview = async(axios, { id }) => {
  return axios.get(`/v1/clients/meal-plans/menu-recommendation/preview/${id}`)
}

export const reqClient_mealPlans_activeProgram = async(
  axios) => {
  return axios.get('/v1/clients/meal-plans/active-program')
}

export const reqClient_mealPlans_preview = async(
  axios, {
    clientId,
    programId,
    month,
  }) => {
  return axios.get('/v1/clients/meal-plans/preview', {
    params: {
      clientId,
      programId,
      month,
    },
  })
}

export const reqClient_mealPlans_mealGlossary_simillarItems = async(
  axios, {
    mealGlossaryId,
  }) => {
  return axios.get(`/v1/clients/meal-plans/meal-glossary/similar-items/${mealGlossaryId}`)
}

export const reqClient_mealPlans_tour_status_get = async(
  axios, {
    programId,
    onboardingName = '',
    page = '',
  }) => {
  return axios.get(`/v1/clients/meal-plans/tour/status?programId=${programId}&onboardingName=${onboardingName}&page=${page}`)
}

export const reqClient_mealPlans_tour_status_put = async(
  axios, {
    onboardingTourUserId,
    skip = 0,
  }) => {
  return axios.put(`/v1/clients/meal-plans/tour/status?onboardingTourUserId=${onboardingTourUserId}&skip=${skip}`)
}