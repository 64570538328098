var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding-bottom": ['64px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": [600],
      "color": "neutral.superDarkGray",
      "margin-bottom": ['16px', '18px'],
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/images/image-in-progress.svg'),
      "width": "100%",
      "height": "auto",
      "max-width": ['200px', '300px'],
      "margin-bottom": ['16px', '18px']
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": [400],
      "color": "neutral.888888",
      "margin-bottom": ['20px', '24px'],
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]), _c('BaseButton', {
    attrs: {
      "min-width": ['250px', '350px']
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: _vm.buttonRoute
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }