var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "max-width": "1270px",
      "margin-inline": "auto",
      "align-items": "center",
      "gap": "80px",
      "padding-block": "20px",
      "margin-top": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400",
      "gap": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.index'
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-left.svg'),
      "height": "20",
      "width": "20"
    }
  }), _vm._v(" Kembali ")], 1), _c('BreadcrumbPath', {
    attrs: {
      "margin-top": "0px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _c('c-box', {
    attrs: {
      "position": "relative",
      "display": "flex",
      "width": "100%",
      "height": "auto",
      "margin-inline": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', 'calc(100vh - 220px)']
    }
  }, [_vm.isLoadingProgram ? _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding": "128px 24px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl",
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Panduan Makan... ")])], 1) : !_vm.isHaveMealPlanMonths ? _c('EmptyState', {
    attrs: {
      "title": "Kamu belum memiliki program aktif",
      "description": "Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu.",
      "button-text": "Beli Program",
      "button-route": "client.select-program"
    }
  }) : _vm.isFirstMealPlanPending ? _c('EmptyState', {
    attrs: {
      "title": "Rencana makanmu masih kosong",
      "description": "Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!",
      "button-text": "Beranda",
      "button-route": "client.index"
    }
  }) : _vm.programMealPlanMonths.length > 0 ? _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": ['#FFF', 'transparent'],
      "padding": ['16px', '0px']
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": ['16px', '20px'],
      "margin-bottom": "24px"
    }
  }, _vm._l(_vm.programMealPlanMonths, function (item, i) {
    return _c('c-flex', {
      key: i,
      class: i === 0 ? 'client_meal-plan_1' : '',
      attrs: {
        "width": "100%",
        "align-items": "center",
        "background-color": "#FFF",
        "padding": ['16px', '20px'],
        "border-radius": "8px",
        "gap": "8px",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "cursor": (item === null || item === void 0 ? void 0 : item.status) === 'pending' ? 'not-allowed' : 'pointer',
        "opacity": (item === null || item === void 0 ? void 0 : item.status) === 'pending' ? '0.7' : '1'
      },
      on: {
        "click": function click($event) {
          return _vm.onClickMealPlanMonth(item);
        }
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "color": "neutral.black",
        "font-size": ['14px', '18px'],
        "font-weight": "500"
      }
    }, [_vm._v(" Bulan " + _vm._s(item === null || item === void 0 ? void 0 : item.month) + " ")]), (item === null || item === void 0 ? void 0 : item.status) === 'done' ? _c('c-box', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "500",
        "color": "primary.400"
      }
    }, [_vm._v(" Program Selesai ")]) : (item === null || item === void 0 ? void 0 : item.status) === 'active' ? _c('c-box', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "500",
        "color": "info.400"
      }
    }, [_vm._v(" Program Aktif ")]) : (item === null || item === void 0 ? void 0 : item.status) === 'pending' ? _c('c-box', {
      attrs: {
        "flex-shrink": "0",
        "width": "20px",
        "height": "20px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-lock-green.svg'),
        "stroke": "#008C81"
      }
    })], 1) : _vm._e()], 1), _c('c-box', {
      attrs: {
        "flex-shrink": "0",
        "width": "20px",
        "height": "20px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icon-chevron-right.svg'),
        "stroke": "#000"
      }
    })], 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('BaseButton', {
    attrs: {
      "min-width": ['250px', '350px'],
      "height": ['50px'],
      "border-radius": "50px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.select-program'
        });
      }
    }
  }, [_vm._v(" Tambah Panduan Makan ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }