<template>
  <c-flex
    width="100%"
    flex-direction="column"
    justify-content="center"
    align-items="center"
    :padding-bottom="['64px', '0px']"
  >
    <c-text
      :font-size="['18px', '20px']"
      :font-weight="[600]"
      color="neutral.superDarkGray"
      :margin-bottom="['16px', '18px']"
      text-align="center"
    >
      {{ title }}
    </c-text>
    <c-image
      :src="require('@/assets/images/image-in-progress.svg')"
      width="100%"
      height="auto"
      :max-width="['200px', '300px']"
      :margin-bottom="['16px', '18px']"
    />
    <c-text
      :font-size="['14px', '16px']"
      :font-weight="[400]"
      color="neutral.888888"
      :margin-bottom="['20px', '24px']"
      text-align="center"
    >
      {{ description }}
    </c-text>
    <BaseButton
      :min-width="['250px', '350px']"
      @click="$router.push({ name: buttonRoute })"
    >
      {{ buttonText }}
    </BaseButton>
  </c-flex>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'

export default {
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonRoute: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>